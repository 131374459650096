<template>
    <div id="landing-container" :class="getMenuType">
        <top-nav />
        <router-view />
        <footer-bar />
    </div>
</template>

<script>
import Sidebar from '../../containers/Sidebar'
import TopNav from '../../containers/TopNav'
import FooterBar from '../../containers/Footer'
import LandPage from '../landing/landpage'
import {
    mapGetters
} from 'vuex'

export default {
    components: {
        'top-nav': TopNav,
        'sidebar': Sidebar,
        'footer-bar': FooterBar,
        'land-page': LandPage
    },
    data() {
        return {
            show: false,
            user: {}
        }
    },
    computed: {
        ...mapGetters(['getMenuType'])
    },
    
}
</script>


<style scoped type="text/css" src="../../assets/css/style.css"></style>
