var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_vm._m(0),_c('section',{class:{ mobileActive : _vm.mobileMenuActive }},[_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),(_vm.user==null)?_c('li',{staticClass:"mobileItem"},[_c('a',{attrs:{"href":"/login"}},[_vm._v("INICIAR SESIÓN")])]):_vm._e(),(_vm.user==null)?_c('li',{staticClass:"mobileItem"},[_c('a',{attrs:{"href":"/register"}},[_vm._v("REGISTRATE")])]):_c('li',{staticClass:"mobileItem"},[_c('a',{attrs:{"href":"/plataforma"}},[_vm._v("ACCEDER A PLATAFORMA")])])])]),_c('section',{attrs:{"id":"btnHolder"}},[(_vm.user==null)?_c('a',{staticClass:"noMobileItem",attrs:{"href":"/login"}},[_vm._v("INICIAR SESIÓN")]):_vm._e(),(_vm.user==null)?_c('a',{staticClass:"noMobileItem",attrs:{"href":"/register"}},[_vm._v("REGISTRATE")]):_c('a',{staticClass:"noMobileItem",attrs:{"href":"/plataforma"}},[_vm._v("ACCEDER A PLATAFORMA")]),(_vm.user!=null)?_c('button',{staticClass:"noMobileItem",attrs:{"type":"button"},on:{"click":_vm.logout}},[_vm._v("CERRAR SESIÓN")]):_vm._e(),_c('button',{staticClass:"mobileItem",on:{"click":function($event){return _vm.toggleMobileMenu()}}},[_c('img',{attrs:{"src":require("../assets/icono-hamburguesa.svg")}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"id":"logoSole","src":require("../assets/logo-blanco-mobile.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("INICIO")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/#about"}},[_vm._v("¿QUÉ ES SOLE?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/#howWorks"}},[_vm._v("¿CÓMO FUNCIONA?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/#plans"}},[_vm._v("PLANES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/#faq"}},[_vm._v("PREGUNTAS FRECUENTES")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/#contact"}},[_vm._v("CONTACTO")])])
}]

export { render, staticRenderFns }