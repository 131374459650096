import firebase from 'firebase/app'
import 'firebase/auth'
import { currentUser } from '../../constants/config'
import axios from 'axios'

export default {
  state: {
    currentUser: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
    loginError: null,
    processing: false,
    forgotMailSuccess:null,
    resetPasswordSuccess:null
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    forgotMailSuccess: state => state.forgotMailSuccess,
    resetPasswordSuccess:state => state.resetPasswordSuccess,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
    },
    setLogout(state) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
    },
    setForgotMailSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.forgotMailSuccess=true
    },
    setResetPasswordSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.resetPasswordSuccess=true
    },
    clearError(state) {
      state.loginError = null
    }
  },
  actions: {
    //MODIFICAR FUNCIONES DE LOGIN, FORGOTPASSWORD, ETC
    login({ commit }, payload) {
      console.log("LOGIN API")
      axios.request({
        data: {
          usuario: payload.email,
          contrasena: payload.password,
          rememberMe: payload.rememberMe
        },
        url: "/api/login",
        method: "post"
      }).then(function(response){
        var responseData = response.data;
        var item = {};

        //user => {
          if(responseData.hasOwnProperty('token')){
            item = { usuario: responseData.usuario, nombre: responseData.nombre, apellidos: responseData.apellidos, role: responseData.role, token: responseData.token, suscripcionInfo: responseData.suscripcionInfo,...currentUser }
            localStorage.setItem('user', JSON.stringify(item))
            commit('setUser', item)
          }else{
        /*},
        err => {*/
            localStorage.removeItem('user')
            //commit('setError',response.error);
            setTimeout(() => {
              commit('clearError')
            }, 3000)
        //}
          }

      }).catch(function(error){
        commit('setError',error.response.data);
      });
    },
    /*forgotPassword({ commit }, payload) {
      axios.request({
        data: {
          correo: payload.email,
        },
        url: "/api/olvide-contrasena",
        method: "post"
      }).then(function(response){
        var responseData = response.data;
        var item = {};

        
          }

      }).catch(function(error){
        commit('setError',error.response.data);
      });
    },*/
    /*resetPassword({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)
      firebase
        .auth()
        .confirmPasswordReset(payload.resetPasswordCode,payload.newPassword)
        .then(
          user => {
            commit('clearError')
            commit('setResetPasswordSuccess')
          },
          err => {
            commit('setError', err.message)
            setTimeout(() => {
              commit('clearError')
            }, 3000)
          }
        )
    },*/

    signOut({ commit }) {
      localStorage.removeItem('user')
      commit('setLogout')
    }
  }
}
