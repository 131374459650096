<template>
	<div id="newRegister">
		<div class="logo">
			<a href="/">
				<img src="/static/img/sole_tracking.png" alt="">
			</a>
		</div>
		<section>
			<h1 v-if="resultadoRegistro === false">FORMULARIO DE REGISTRO</h1>
			<form id="formRegister" v-if="resultadoRegistro === false">
				<div>
					<input type="text" name="name" placeholder="NOMBRE(S)" v-model="formRegistro.usuario.nombre">
				</div>
				<div>
					<input type="text" name="apellidos" placeholder="APELLIDOS" v-model="formRegistro.usuario.apellidos" >
				</div>
				<div id="mailinput">
					<input type="email" name="email" placeholder="CORREO ELECTRÓNICO" v-model="formRegistro.usuario.correo"> 
				</div>
				<div>
					<input type="password" name="password1" placeholder="CONTRASEÑA" v-model="formRegistro.usuario.contrasena">
				</div>
				<div>
					<input type="password" name="password" placeholder="CONFIRMAR CONTRASEÑA" v-model="formRegistro.usuario.confirmContrasena">
				</div>
				<label><input type="checkbox" name=""> HE LEIDO Y ACEPTO LOS <a href="">TÉRMINOS Y CONDICIONES</a></label>
				<div id="formButton">
					<button type="button" :click="funcionRegistrar()">REGISTRARME AHORA</button>	
				</div>
			</form>
			<div v-else>
				<div class="section-title">
					<h1>Registro completado</h1>
					<p>Se ha enviado un correo de verificación a la cuenta <strong>{{formRegistro.usuario.correo}}</strong></p>
				</div>
			</div>
		</section>
	</div>		
</template>

<script> 
	export default { 
		data(){ 
			return {
				formRegistro: {
					familia: {
						nombreEncargado: "",
						apellidos_familia: "",
						correo: "",
						telefono1: ""
					},
					usuario: {
						nombre: "",
						apellidos: "",
						genero: "",
						telefono: "",
						correo: "",
						contrasena: "",
						confirmContrasena:""
					}					 
				},
				resultadoRegistro: false
			}
		}, 
		created() { 
			//var instance = this;
		}, 
		methods: { 
			/*Funcion que esta conectada a la funcion de*/
			funcionRegistrar: function(){
				var instance = this;
				var form = document.getElementById('formRegister');

				/*if (form.checkValidity() === false) {
		          console.log("VALIDITY = FALSE")
		        }else{*/
		        	instance.formRegistro.familia.nombreEncargado = instance.formRegistro.usuario.nombre
		        	instance.formRegistro.familia.apellidos_familia = instance.formRegistro.usuario.apellidos
		        	instance.formRegistro.familia.correo = instance.formRegistro.usuario.correo
		        	
		        	axios.request({
						url: '/api/registrarme',
						method: 'post',
						data: instance.formRegistro
					}).then(function(response){
						if (response.data.status) {
							console.log("Info enviada!");
							instance.resultadoRegistro = true;
						}else {
							console.log("Error!");
						}
					}).catch(function(error){
						console.log("Intenta de nuevo!");
					});
		        //}
		        //form.classList.add('was-validated');		        
			},

		} 
	} 
</script>
<style lang="less">
#newRegister{
	padding-top: 120px;

	.logo{
		padding-top: 4em;
		img{
			width: 200px;
		}
	}

	section{
		padding: 4em 25vw;
		
		h1{
			background: linear-gradient(90deg, #00ACD8, #CB007B);
		    color: transparent;
		    background-clip: text;
		    font-family: 'Montserrat-bold';
		    font-size: 35pt;
		    display: inline-block;
		    margin-bottom: 1em;	
		}
		
		form{
			text-align: center;
			display: flex;
		    flex-direction: row;
		    flex-wrap: wrap;
		    align-content: center;
		    justify-content: flex-start;
		    align-items: center;
			

			div{
				width: 50%;

				&#mailinput{
					width: 100%;

					input{
						width: 97%;
					}
				}

				input{
					background-color: #E8E8E8;
				    color: black;
				    border: none;
				    margin-bottom: 1.5em;
				    width: 94%;
				    padding: 1em 2em;
				    text-align: left;
				}

				&#formButton{
					width: 100%;
					text-align: center;

					button{
                        background-color: #00acd8;
                        color: white;
                        border: none;
                        padding: 1em 4em;
                        margin-bottom: 2em;
                        margin-top: 2em;
                    }
				}
			}

			label{
				display: block;
				width: 100%;
				text-align: left;
				padding-left: 1.5%;
			}

			
		}

		p{
			font-size: 14pt;
		}
	}

	@media (max-width: 1024px){
		section{
			padding: 2em 5vw;

			form{

				div{
					width: 100%;

					input,
					&#mailinput input{
						width: 100%;
					}
				}
			}
		}
	}

}
</style>
