<template>
    <div id="newLogin">
        <section>
            <img src="../../assets/img/logo-sole-cr.svg">
            <form v-on:keyup.enter="formSubmit()">
                <input type="email" name="email" placeholder="CORREO ELECTRÓNICO"  v-model="$v.form.email.$model">
                <span class="alertform" v-if="!$v.form.email.required"></span>
                <span class="alertform" v-else-if="!$v.form.email.email">Por favor ingrese un correo electrónico valido</span>
                <span class="alertform" v-else-if="!$v.form.email.minLength">Su correo debe contener al menos 4 caracteres</span>
                <input type="password" name="password" placeholder="CONTRASEÑA" v-model="$v.form.password.$model" >
                <label>
                    <input type="checkbox" name=""  v-model="$v.form.rememberMe.$model">
                    MANTENER LA SESIÓN ABIERTA
                </label>
                <button type="button" v-on:click="formSubmit()">INICIAR SESIÓN</button>
                <br>
                <a href="/forgot-password">¿Olvidó su contraseña?</a>
            </form>
        </section>
        <section>
            <img src="../../assets/img/foto-iniciar-sesion.webp">
        </section>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    validationMixin
} from "vuelidate";
const {
    required,
    maxLength,
    minLength,
    email
} = require("vuelidate/lib/validators");

export default {
    data() {
        return {
            form: {
                email: "",
                password: "",
                rememberMe: false
            },
            errorVerificacion: false
        };
    },
    mixins: [validationMixin],
    validations: {
        form: {
            password: {
                required,
                minLength: minLength(4)
            },
            email: {
                required,
                email,
                minLength: minLength(4)
            },
            rememberMe: {
                required
            }
        }
    },
    computed: {
        ...mapGetters(["currentUser", "processing", "loginError"])
    },
    methods: {
        ...mapActions(["login"]),
        formSubmit() {
            this.$v.$touch();
            //this.form.email = "piaf-vue@coloredstrategies.com";
            //this.form.password = "piaf123";
            this.$v.form.$touch();
           // if (!this.$v.form.$anyError) {
                this.login({
                    email: this.form.email,
                    password: this.form.password,
                    rememberMe: this.form.rememberMe
                });
            //}
        }
    },
    watch: {
        currentUser(val) {
            
            if (val.token.length > 0) {
                //setTimeout(() => {
                    this.$router.push("/plataforma").catch((error) => {
                        console.log(error);
                    });
                //}, 500);
            }
        },
        loginError(val) {

            if (val != null) {
                this.$notify("error", "Error al iniciar sesión", val.error, {
                    duration: 3000,
                    permanent: false
                });

                
                if(val.status == 409){
                    //significa que no ha verificado su correo
                    this.errorVerificacion = true;
                }
            }
        }
    },
    mounted: function(){
        /*console.log("Cookies");
        console.log(Document.cookie);*/
    }
};
</script>
<style lang="less">
    #newLogin{
        background-color: white;
        padding: 120px 10vw 0;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;

        section{
            width: 50%;

            &:first-child{
                padding: 4vw;
                text-align: left;

                img{
                    margin-bottom: 2em;
                }

                form{
                    text-align:left;

                    input{
                        text-align: left;
                        width: 100%;
                        background-color: #E8E8E8;
                        color: black;
                        border: none;
                        margin-bottom: 2em;
                        font-size: 12pt;
                        padding: 0.5em 1em;

                        &[type=checkbox]{
                            width: auto;
                        }
                    }

                    label{
                        width: 100%;
                        text-align: left;
                    }

                    button{
                        background-color: #00acd8;
                        color: white;
                        border: none;
                        padding: 1em 4em;
                        margin-bottom: 2em;
                    }

                    a{
                        color: #00acd8
                    }

                    span.alertform{
                        color: red;
                    }
                }
            }


            &:last-child{
                padding: 8em 4em 0;

                img{
                    width: 100%;
                }
            }
        }

        h2{
            font-family: 'Montserrat-bold';
            font-size: 35pt;
            color: white;
            display: inline-block;
            margin: 2rem 0;
        }

        span{
            color: white;
            max-width: 700px;
            display: inline-block;
            padding-bottom: 2em;
        }  
    }

    @media (max-width: 1024px){
        #newLogin{
            flex-direction: column;

            section{
                width: 100%;

                &:first-child,
                &:first-child form,
                &:first-child form label{
                    text-align: center;
                }

                &:last-child{
                    padding-top: 2em;
                }
            }
        }
    }
</style>