<template>
<b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
        <b-card class="auth-card" no-body>
            <div class="position-relative image-side">
                <p class="text-white h2">{{ $t('dashboards.magic-is-in-the-details') }}</p>
                <p class="white mb-0">
                    Lamentamos la eliminación de su cuenta
                    <br />
                </p>
            </div>
            <div class="form-side">
                <router-link tag="a" to="/">
                    <img class="customLogo loginLogo" src="/static/img/logo-sole.svg">
                </router-link>
				<h6>Por favor indiquenos el motivo de su eliminación</h6>
				<textarea name="" id="" v-model="otherReason"></textarea>
				<br/>
				<b-button class="" @click="verificacion()" variant="danger" size="lg">Eliminar cuenta</b-button>
                <h6 class="mb-4" v-if="!verificado">Si no desea realizar la eliminación por favor haga caso omiso a esta interfaz</h6>
                <h6 class="mb-4" v-else>Su cuenta será eliminada en 5 minutos, los usuarios de la cuenta estarán deshabilitados hasta la eliminacion, gracias por utilizar Sole Tracking®</h6>
                
            </div>
        </b-card>
    </b-colxx>
</b-row>
</template>

<script>

	export default {
	    data() {
	        return {
				selectedReason:"other",
				otherReason:"",
				verificado: false,
	            mensajeVerificado: "",
	            status: false
	        };
	    },
	    mounted(){
	    },
	    methods: {
	       	verificacion: function(){
	            var instance = this;
	            var params =  new URLSearchParams(window.location.search);

	            //instance.$parent.toggleLoader("Verificando cuenta");
	            if(params.get("email") != null && params.get("tkn") != null){
	            	console.log(params);

		            axios.request({
		                method: "delete",
		                url:"/api/cliente/verificar-rm/" + params.get("email") + "/" + params.get("tkn") ,
		                //headers:{ Authorization: "Bearer " + token }
		            }).then(response => {
		                
		                if(response.data.data > 0){
		                    instance.mensajeVerificado = response.data.data;
		                    instance.verificado = true;
		                }else{
		                	instance.mensajeVerificado = response.data.error;
		                }

		                //instance.$parent.toggleLoader();
		            });
		        }else{
		        	instance.mensajeVerificado = "Enlace inválido, favor de dar click en el enlace enviado a su correo electrónico";
		        }
	        }
	    },
	};
</script>
<style lang="less">
textarea{
	width: 100%;
	resize: none;
	height: 180px;
}
</style>