<template>
    <div >
        <top-nav />
        <router-view />
        <footer-bar />
    </div>
</template>
<script>
import TopNav from '../../containers/TopNav'
import FooterBar from '../../containers/Footer'

export default {
    components: {
        'top-nav': TopNav,
        'footer-bar': FooterBar,
    },
    data() {
        return {
            show: false,
            user: {}
        }
    },
  mounted () {
    document.body.classList.add('background')
  },
  beforeDestroy () {
    document.body.classList.remove('background')
  }
}
</script>
