<template>
    <header>
        <section>
            <a href="/"><img id="logoSole" src="../assets/logo-blanco-mobile.svg"></a>
        </section>
        <section :class="{ mobileActive : mobileMenuActive }">
            <ul>
                <li> <a href="/">INICIO</a></li>
                <li> <a href="/#about">¿QUÉ ES SOLE?</a></li>
                <li> <a href="/#howWorks">¿CÓMO FUNCIONA?</a></li>
                <li> <a href="/#plans">PLANES</a></li>
                <li> <a href="/#faq">PREGUNTAS FRECUENTES</a></li>
                <li> <a href="/#contact">CONTACTO</a></li>
                <li class="mobileItem" v-if="user==null"><a href="/login">INICIAR SESIÓN</a></li>
                <li class="mobileItem" v-if="user==null"><a href="/register">REGISTRATE</a></li>
                <li class="mobileItem" v-else><a href="/plataforma">ACCEDER A PLATAFORMA</a></li>
            </ul>
        </section>
        <section id="btnHolder">
            <a href="/login" class="noMobileItem" v-if="user==null">INICIAR SESIÓN</a>
            <a href="/register" class="noMobileItem" v-if="user==null">REGISTRATE</a>
            <a href="/plataforma" class="noMobileItem" v-else>ACCEDER A PLATAFORMA</a>
			<button type="button" @click="logout" class="noMobileItem" v-if="user!=null">CERRAR SESIÓN</button>
            <button class="mobileItem" @click="toggleMobileMenu()">
                <img src="../assets/icono-hamburguesa.svg">
            </button>
        </section>
    </header>
</template>

<script>
export default{
    data(){
        return{
            mobileMenuActive: false,
            user: null,
        }
    },

    mounted(){
        this.currentUser();
    },

    methods:{
        toggleMobileMenu(){
            this.mobileMenuActive = !this.mobileMenuActive;
        },

        currentUser: function(){
            let localUser = localStorage.getItem('user');

            if(localUser != null){
                this.user = JSON.parse(localUser);
            }
        },
		
		logout(){
			localStorage.removeItem('user');
			this.$router.push('/login')
		}
    }
}
</script>

<style lang="less">
@primaryColor: #00ACD8;
@secondaryColor: #CB007B;
@thirdColor: #E8E8E8;

.mobileItem{
    display: none;
}

header{
    z-index: 100;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    background: linear-gradient(45deg,@primaryColor,@secondaryColor);
    color: white;
    font-size: 10pt;
    padding: 1em;
    height: 120px;

    section:first-child{
        padding-right: 2em;
        
        img#logoSole{
            width: 90px;
        }   
    }
    

    ul{
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
        padding: 0;
        margin-bottom: 0;

        li{
            min-width: 70px;
            cursor: pointer;
            padding: 1.1em 1em;

            a,
            a:active,
            a:hover{
                color: white;
            }
        }
    }

    section#btnHolder{
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
        padding: 1.5em 0em;

        button,a{
            color: white;
            padding: 0.5em 0.5em;
            margin-right: 1em;
            cursor: pointer;
        }

        button:first-child,
        a:first-child{
            background: transparent;
            border: 2px white solid;
        }

        button:nth-child(2),
        a:nth-child(2){
            background: @primaryColor;
            border: 2px @primaryColor solid;
        }
    }
}

@media (max-width: 1024px){
    .mobileItem{
        display: block;
    }

    .noMobileItem{
        display: none;
    }

    header{
        justify-content: space-between;
        padding-top: 0;
        padding-bottom: 0;
        height: 120px;

        section:first-child{
            padding-left: 1.5em;

            img#logoSole{
                width: 80px;
            }
        }

        section:nth-child(2){
            position: fixed;
            background-color: @thirdColor;
            width: 100%;
            top: 90px;
            left: 0;
            display: none;
            color: black;

            ul{
                display: block;

                li:hover{
                    background-color: @secondaryColor;
                    color: white;
                }
            }

            &.mobileActive{
                display: block;
            }
        }

        #btnHolder button:last-child,
        #btnHolder a:last-child{
            color: @thirdColor;
            background-color: transparent;
            border: none;
            padding-right: 1.5em;

            img{
                width: 40px;
            }
        }

        ul{
            li{
                a,
                a:active,
                a:hover{
                    color: black;
                }
            }
        }
    }
}
</style>