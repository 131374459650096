<template>
  <div class="h-100">
    <router-view />    
  </div>  
</template>

<script>
import ColorSwitcher from "./components/Common/ColorSwitcher";
import { getDirection } from "./utils";

export default {
  data(){
    return {
      activeLoader: false
    }
  },

  components: {
    "color-switcher": ColorSwitcher
  },

  beforeMount() {
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add("rtl");
      document.dir = "rtl";
      document.body.classList.remove("ltr");
    } else {
      document.body.classList.add("ltr");
      document.dir = "ltr";
      document.body.classList.remove("rtl");
    }

   
  },
  
};
</script>
<style lang="less">

*{
  font-family: Montserrat-Light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

